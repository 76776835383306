import { FETCH_QUOTES, SET_LOADING, LOG_ERROR } from './quote.types';

const initialState = {
  loading: false,
  error: null,
  quotes: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_QUOTES:
      return {
        ...state,
        quotes: action.payload,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOG_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
