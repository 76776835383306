import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../spinner/Spinner.component';
import { fetchGallery } from '../../redux/gallery/gallery.actions';
import { setLightbox } from '../../redux/lightbox/lightbox.actions';
import { Link } from 'react-router-dom';
import { findIndex } from '../../utils/postFilters';
import './WallGalleryDisplay.styles.scss';

class GalleryDisplay extends Component {
  componentDidMount() {
    this.props.fetchGallery(this.props.id);
  }

  layoutRandomizer = (width, height) => {
    if (width > height) {
      return (
        'wall-gallery-display__image--landscape' +
        (Math.floor(Math.random() * 5) + 1)
      );
    }

    return (
      'wall-gallery-display__image--portrait' +
      (Math.floor(Math.random() * 5) + 1)
    );
  };

  onThumbnailClick = (gallery, id) => {
    this.props.setLightbox(gallery, id);
  };

  render() {
    const { categories } = this.props.gallery;
    const { id } = this.props;

    if (!categories) {
      return <Spinner />;
    }

    const indexOfId = findIndex(categories, id);

    if (categories[indexOfId].gallery) {
      return (
        <section className="wall-gallery-display">
          <div className="wall-gallery-display__hero">
            <div className="wall-gallery-display__hero-copy">
              <h2 className="wall-gallery-display__header">Latest Gallery</h2>
              <h3 className="wall-gallery-display__sub-header">
                {categories[indexOfId].name}
              </h3>
              <p className="wall-gallery-display__description">
                {categories[indexOfId].description}
              </p>
            </div>

            {categories[indexOfId].gallery.length > 0 && (
              <div className="wall-gallery-display__hero-image">
                <picture
                  onClick={this.onThumbnailClick.bind(
                    this,
                    categories[indexOfId].gallery,
                    categories[indexOfId].gallery[0].id
                  )}
                >
                  <source
                    alt={categories[indexOfId].gallery[0].alt_text}
                    srcSet={
                      categories[indexOfId].gallery[0].media_details.sizes
                        .medium.source_url
                    }
                    media="(max-width: 767px)"
                  />
                  <img
                    alt={categories[indexOfId].gallery[0].alt_text}
                    src={
                      categories[indexOfId].gallery[0].media_details.sizes.large
                        .source_url
                    }
                  />
                </picture>
              </div>
            )}
          </div>
          {categories[indexOfId].gallery.length > 5 &&
            categories[indexOfId].gallery.slice(1, 5).map((image) => (
              <div
                className={
                  'wall-gallery-display__image ' +
                  this.layoutRandomizer(
                    image.media_details.sizes.large.width,
                    image.media_details.sizes.large.height
                  )
                }
                key={image.id}
              >
                <picture
                  onClick={this.onThumbnailClick.bind(
                    this,
                    categories[indexOfId].gallery,
                    image.id
                  )}
                >
                  <source
                    alt={image.alt_text}
                    srcSet={image.media_details.sizes.medium.source_url}
                    media="(max-width: 767px)"
                  />
                  <img
                    alt={image.alt_text}
                    src={image.media_details.sizes.large.source_url}
                  />
                </picture>
              </div>
            ))}

          {categories[indexOfId].gallery.length > 5 && (
            <div className="wall-gallery-display__cta">
              <div className="wall-gallery-display__cta-image">
                <picture
                  onClick={this.onThumbnailClick.bind(
                    this,
                    categories[indexOfId].gallery,
                    categories[indexOfId].gallery[6].id
                  )}
                >
                  <source
                    alt={categories[indexOfId].gallery[6].alt_text}
                    srcSet={
                      categories[indexOfId].gallery[6].media_details.sizes
                        .medium.source_url
                    }
                    media="(max-width: 767px)"
                  />
                  <img
                    alt={categories[indexOfId].gallery[6].alt_text}
                    src={
                      categories[indexOfId].gallery[6].media_details.sizes.large
                        .source_url
                    }
                  />
                </picture>
              </div>
              <div className="wall-gallery-display__cta-btn">
                <Link className="btn-std" to={`/gallery/`}>
                  View all galleries
                </Link>
              </div>
            </div>
          )}
        </section>
      );
    }

    return <Spinner />;
  }
}

const mapStateToProps = (state) => ({
  gallery: state.gallery,
});

export default connect(mapStateToProps, {
  fetchGallery,
  setLightbox,
})(GalleryDisplay);
