import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchGallery } from '../../redux/gallery/gallery.actions';
import WallBlogPost from './WallBlogPost.component';
import WallKitchenPost from './WallKitchenPost.component';
import WallLatestGallery from './WallGalleryDisplay.component';
import WallHero from './wall-hero/WallHero.component';

class Wall extends Component {
  componentDidMount() {
    this.props.fetchGallery();
  }

  render() {
    const { latestCategory } = this.props.gallery;

    return (
      <>
        <WallHero />
        <WallBlogPost match={{ params: { id: null } }} />
        {latestCategory && <WallLatestGallery id={latestCategory} />}
        <WallKitchenPost match={{ params: { id: null } }} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  gallery: state.gallery,
});

export default connect(mapStateToProps, { fetchGallery })(Wall);
