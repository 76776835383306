import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../spinner/Spinner.component';
import { fetchGallery } from '../../../redux/gallery/gallery.actions';
import { setLightbox } from '../../../redux/lightbox/lightbox.actions';
import { findIndex } from '../../../utils/postFilters';

class GalleryDisplay extends Component {
  componentDidMount() {
    this.props.fetchGallery(this.props.id);
  }

  render() {
    const { categories } = this.props.gallery;
    const { id } = this.props;

    if (!categories) {
      return <Spinner />;
    }

    const indexOfId = findIndex(categories, id);

    if (categories[indexOfId].gallery) {
      const randomImage =
        categories[indexOfId].gallery[
          Math.floor(Math.random() * categories[indexOfId].gallery.length)
        ];
      return (
        <div className="wall-hero__image">
          <picture>
            <source
              alt={randomImage.alt_text}
              srcSet={randomImage.media_details.sizes.medium.source_url}
              media="(max-width: 767px)"
            />
            <source
              alt={randomImage.alt_text}
              srcSet={randomImage.media_details.sizes.large.source_url}
              media="(max-width: 1023px)"
            />
            <img
              className="wall-hero__image-img"
              alt={randomImage.alt_text}
              src={randomImage.media_details.sizes.full.source_url}
            />
          </picture>
        </div>
      );
    }

    return <Spinner />;
  }
}

const mapStateToProps = (state) => ({
  gallery: state.gallery,
});

export default connect(mapStateToProps, {
  fetchGallery,
  setLightbox,
})(GalleryDisplay);
