import React from 'react';
import './Footer.styles.scss';

function Footer() {
  return (
    <footer className="app-footer">
      <div className="app-footer__inner-wrapper">
        <nav className="app-footer__navigation">
          <h4 className="app-footer__navigation-header">Footer Navigation</h4>
          <ul className="app-footer__navigation-list">
            <li className="app-footer__navigation-list-item">
              <a
                className="app-footer__navigation-list-item-link app-footer__navigation-list-item-link--instagram"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/ileilakhan/"
              >
                Instagram
              </a>
            </li>
            <li className="app-footer__navigation-list-item">
              <a
                className="app-footer__navigation-list-item-link app-footer__navigation-list-item-link--twitter"
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/Leila_Khan?lang=en"
              >
                Twitter
              </a>
            </li>
            <li className="app-footer__navigation-list-item">
              <a
                className="app-footer__navigation-list-item-link app-footer__navigation-list-item-link--tumblr"
                target="_blank"
                rel="noopener noreferrer"
                href="http://ileilakhan.tumblr.com/"
              >
                Tumblr
              </a>
            </li>
            <li className="app-footer__navigation-list-item">
              <a
                className="app-footer__navigation-list-item-link app-footer__navigation-list-item-link--pinterest"
                target="_blank"
                rel="noopener noreferrer"
                href="https://nz.pinterest.com/ileilakhan/"
              >
                Pinterest
              </a>
            </li>
          </ul>
        </nav>
        <p className="app-footer__copyright">
          &copy; Leila Khan {new Date().getFullYear()}.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
