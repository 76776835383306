import React from 'react';
import composedHOC from './LoadPosts.hoc';
import { filterByCategory } from '../../utils/postFilters';
import { Link } from 'react-router-dom';
import { KITCHEN_POSTS_CATEGORY } from './postCategory.types';
import './KitchenPosts.styles.scss';

const KitchenPosts = (props) => {
  const { post } = props;
  return (
    <section className="kitchen-posts">
      <h1 className="kitchen-posts__header">Kitchen Posts</h1>
      {post.map((post) => (
        <div key={post.id} className="kitchen-posts__item">
          <div className="kitchen-posts__item-inner-wrapper">
            <div className="kitchen-posts__item-copy">
              <Link
                className="kitchen-posts__item-header-link"
                to={`/kitchen/${post.id}`}
              >
                <h2 className="kitchen-posts__item-header">
                  {post.title.rendered}
                </h2>
              </Link>
              <div
                className="kitchen-posts__item-description"
                dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
              />
            </div>
            <Link
              className="kitchen-posts__item-hero-link"
              to={`/kitchen/${post.id}`}
            >
              <picture>
                <source
                  alt={post.title.rendered}
                  srcSet={
                    post._embedded['wp:featuredmedia'][0].media_details.sizes
                      .medium.source_url
                  }
                  media="(max-width: 767px)"
                />
                <img
                  className="kitchen-posts__item-hero-image"
                  alt={post._embedded['wp:featuredmedia'][0].alt_text}
                  src={
                    post._embedded['wp:featuredmedia'][0].media_details.sizes
                      .large.source_url
                  }
                />
              </picture>
            </Link>
          </div>
        </div>
      ))}
    </section>
  );
};

export default composedHOC(
  KitchenPosts,
  filterByCategory,
  KITCHEN_POSTS_CATEGORY
);
