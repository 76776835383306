import React from 'react';
import composedHOC from './LoadPosts.hoc';
import { filterById } from '../../utils/postFilters';
import './BlogPost.styles.scss';

const BlogPost = (props) => {
  const { post } = props;
  return (
    <section className="blog-post">
      <div className="blog-post__inner-wrapper">
        <h2 className="blog-post__header">{post[0].title.rendered}</h2>
        <div dangerouslySetInnerHTML={{ __html: post[0].content.rendered }} />
      </div>
    </section>
  );
};

export default composedHOC(BlogPost, filterById, null);
