// Gallery Types
export const FETCH_GALLERIES = 'FETCH_GALLERIES';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const SET_LOADING = 'SET_LOADING';
export const SET_LOADING_CATEGORIES = 'SET_LOADING_CATEGORIES';
export const LOG_ERROR = 'LOG_ERROR';
export const SET_GALLERY = 'GET_GALLERY';
export const CLOSE_GALLERY = 'CLOSE_GALLERY';
export const NEXT_IMAGE_GALLERY = 'NEXT_IMAGE_GALLERY';
export const PREV_IMAGE_GALLERY = 'PREV_IMAGE_GALLERY';
