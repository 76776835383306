import React from 'react';
import composedHOC from '../post/LoadPosts.hoc';
import { filterByLatest } from '../../utils/postFilters';
import { Link } from 'react-router-dom';
import { KITCHEN_POSTS_CATEGORY } from '../post/postCategory.types';
import './WallKitchenPost.styles.scss';

const WallKitchenPost = (props) => {
  const { post } = props;
  return (
    <section className="wall-kitchen-post-item">
      <div className="wall-kitchen-post__inner-wrapper">
        <div className="wall-kitchen-post__item-copy">
          <Link
            className="wall-kitchen-post__item-header-link"
            to={`/kitchen/${post[0].id}`}
          >
            <h2 className="wall-kitchen-post__item-header">Latest Recipie</h2>
          </Link>
          <h3 className="wall-kitchen-item-sub-header">
            {post[0].title.rendered}
          </h3>
          <div
            className="wall-kitchen-post__item-description"
            dangerouslySetInnerHTML={{ __html: post[0].excerpt.rendered }}
          />
          <Link className="btn-std" to={`/kitchen/${post[0].id}`}>
            See recipie
          </Link>
        </div>
        {post[0]._embedded['wp:featuredmedia'] && (
          <Link to={`/kitchen/${post[0].id}`}>
            <picture className="wall-kitchen-post__item-hero-image">
              <source
                alt={post[0].title.rendered}
                srcSet={
                  post[0]._embedded['wp:featuredmedia'][0].media_details.sizes
                    .medium.source_url
                }
                media="(max-width: 767px)"
              />
              <img
                className="wall-hero__image-img"
                alt={post[0].title.rendered}
                src={
                  post[0]._embedded['wp:featuredmedia'][0].media_details.sizes
                    .large.source_url
                }
              />
            </picture>
          </Link>
        )}
      </div>
    </section>
  );
};

export default composedHOC(
  WallKitchenPost,
  filterByLatest,
  KITCHEN_POSTS_CATEGORY
);
