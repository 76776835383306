import { FETCH_QUOTES, SET_LOADING, LOG_ERROR } from './quote.types';

export const fetchQuotes = () => async (dispatch) => {
  try {
    dispatch(setLoading());

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/lk-cms/wp-json/wp/v2/quotes`
    );
    const data = await res.json();

    dispatch({
      type: FETCH_QUOTES,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: LOG_ERROR,
      payload: err,
    });
  }
};

export const setLoading = () => {
  return {
    type: SET_LOADING,
  };
};

export const logError = () => {};
