import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchQuotes } from '../../../redux/quote/quote.actions';
import Spinner from '../../spinner/Spinner.component';
import ScrollIcon from '../../../components/scroll-icon/scroll-icon.component';

class WallHeroQuotes extends Component {
  componentDidMount() {
    const { quotes } = this.props.quote;

    if (!quotes) {
      this.props.fetchQuotes();
    }
  }

  render() {
    const { quotes } = this.props.quote;

    if (quotes) {
      const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];

      return (
        <article className="wall-hero__quote-wrapper">
          <h2 className="wall-hero__quote-heading">Randomly selected Quote</h2>
          <div className="wall-hero__quote-copy">
            <div
              className="wall-hero__rendered"
              dangerouslySetInnerHTML={{ __html: randomQuote.content.rendered }}
            />
          </div>
          <ScrollIcon />
        </article>
      );
    }

    return <Spinner />;
  }
}

const mapStateToProps = (state) => {
  return {
    quote: state.quote,
  };
};

export default connect(mapStateToProps, { fetchQuotes })(WallHeroQuotes);
