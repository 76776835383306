import React from 'react';
import composedHOC from './../post/LoadPosts.hoc';
import { filterByLatest } from '../../utils/postFilters';
import { Link } from 'react-router-dom';
import { BLOG_POSTS_CATEGORY } from '../post/postCategory.types';
import './WallBlogPost.styles.scss';

const WallBlogPost = (props) => {
  const { post } = props;
  return (
    <article className="wall-blog-post">
      <div className="wall-blog-post__innerWrapper">
        <div className="wall-blog-post__grid">
          <div className="wall-blog-post__title">
            <div>
              <h2 className="wall-blog-post__title-heading">
                {post[0].title.rendered}
              </h2>
              <p className="wall-blog-post__title-date">
                {post[0].formatted_date}
              </p>
            </div>
          </div>
          <div className="wall-blog-post__copy">
            <div
              className="wall-blog-post__copy-excerpt"
              dangerouslySetInnerHTML={{ __html: post[0].excerpt.rendered }}
            />

            <Link className="btn-std" to={`/blog/${post[0].id}`}>
              Read more
            </Link>
          </div>
          {post[0]._embedded['wp:featuredmedia'] && (
            <picture className="wall-blog-post__image">
              <source
                alt={post[0]._embedded['wp:featuredmedia'][0].alt_text}
                srcSet={
                  post[0]._embedded['wp:featuredmedia'][0].media_details.sizes
                    .medium.source_url
                }
                media="(max-width: 767px)"
              />
              <img
                className="wall-hero__image-img"
                alt={post[0]._embedded['wp:featuredmedia'][0].alt_text}
                src={
                  post[0]._embedded['wp:featuredmedia'][0].media_details.sizes
                    .large.source_url
                }
              />
            </picture>
          )}
        </div>
      </div>
    </article>
  );
};

export default composedHOC(WallBlogPost, filterByLatest, BLOG_POSTS_CATEGORY);
