import { combineReducers } from 'redux';
import postReducer from './post/post.reducer';
import galleryReducer from './gallery/gallery.reducer';
import quoteReducer from './quote/quote.reducer';
import lightboxReducer from './lightbox/lightbox.reducer';

export default combineReducers({
  gallery: galleryReducer,
  posts: postReducer,
  lightbox: lightboxReducer,
  quote: quoteReducer,
});
