import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { ReactComponent as Hamburger } from './btn-hamburger.svg';

import './Header.styles.scss';
import './Hamburger.styles.scss';

class Header extends Component {
  state = {
    showPageHeader: true,
    showHeaderNavigation: false,
    currentScrollPosition: 0,
    lastScrollPosition: 0,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleToggleClick = () => {
    if (this.state.showHeaderNavigation === false) {
      this.setState({ showHeaderNavigation: true });
    } else {
      this.setState({ showHeaderNavigation: false });
    }
  };

  handleCloseHeaderNav = () => {
    this.setState({ showHeaderNavigation: false });
  };

  handleScroll = () => {
    // lastScrollPosition
    this.setState({ lastScrollPosition: this.state.currentScrollPosition });

    // currentScrollPosition
    this.setState({ currentScrollPosition: window.pageYOffset });

    if (this.state.currentScrollPosition < this.state.lastScrollPosition) {
      // scrolling up
      this.setState({ showPageHeader: true });
      this.setState({ showHeaderNavigation: false });
    } else {
      // scrolling down
      this.setState({ showPageHeader: false });
    }

    if (window.pageYOffset === 0) {
      this.setState({ showPageHeader: true });
    }
  };

  render() {
    return (
      <header
        className={`app-header ${
          this.state.showPageHeader ? '' : 'app-header--inactive'
        }`}
      >
        <div className="app-header__inner-wrapper">
          <h3 className="app-header__logo">
            <Link
              onClick={this.handleCloseHeaderNav}
              className="app-header__logo-link"
              to="/"
            >
              <span className="offscreen">Website of </span>Leila Khan
            </Link>
          </h3>
          <nav className="app-header__navigation">
            <button
              onClick={this.handleToggleClick}
              className={`app-header__toggle-btn ${
                this.state.showHeaderNavigation
                  ? ' app-header__toggle-btn--active'
                  : ''
              }`}
            >
              <span className="app-header__toggle-btn-copy">
                Toggle Mobile Navigation
              </span>
              <Hamburger />
            </button>
            <h4 className="app-header__navigation-header">Header Navigation</h4>
            <ul
              onClick={this.handleCloseHeaderNav}
              className={`app-header__navigation-list ${
                this.state.showHeaderNavigation
                  ? ''
                  : 'app-header__navigation-list--inactive'
              }`}
            >
              <li className="app-header__navigation-list-item">
                <NavLink
                  className="app-header__navigation-list-item-link"
                  activeClassName="app-header__navigation-list-item-link--active"
                  to="/"
                  exact
                >
                  Wall
                </NavLink>
              </li>
              <li className="app-header__navigation-list-item">
                <NavLink
                  className="app-header__navigation-list-item-link"
                  activeClassName="app-header__navigation-list-item-link--active"
                  to="/blog"
                >
                  Blog
                </NavLink>
              </li>
              <li className="app-header__navigation-list-item">
                <NavLink
                  className="app-header__navigation-list-item-link"
                  activeClassName="app-header__navigation-list-item-link--active"
                  to="/gallery"
                >
                  Gallery
                </NavLink>
              </li>
              <li className="app-header__navigation-list-item">
                <NavLink
                  className="app-header__navigation-list-item-link"
                  activeClassName="app-header__navigation-list-item-link--active"
                  to="/kitchen"
                >
                  Kitchen
                </NavLink>
              </li>
              <li className="app-header__navigation-list-item">
                <NavLink
                  className="app-header__navigation-list-item-link"
                  activeClassName="app-header__navigation-list-item-link--active"
                  to="/about"
                >
                  About Me
                </NavLink>
              </li>
              <li className="app-header__navigation-list-item">
                <NavLink
                  className="app-header__navigation-list-item-link"
                  activeClassName="app-header__navigation-list-item-link--active"
                  to="/contact"
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;
