import {
  CLOSE_LIGHTBOX,
  NEXT_IMAGE_CHANGE,
  PREV_IMAGE_CHANGE,
  SET_LIGHTBOX,
} from './lightbox.types';

export const closeLightbox = () => {
  return {
    type: CLOSE_LIGHTBOX,
  };
};

export const nextImageChange = () => {
  return {
    type: NEXT_IMAGE_CHANGE,
  };
};

export const prevImageChange = () => {
  return {
    type: PREV_IMAGE_CHANGE,
  };
};

export const setLightbox = (gallery, id) => {
  const indexOfId = gallery.findIndex((x) => x.id === id);
  const payload = { gallery, indexOfId };
  return {
    type: SET_LIGHTBOX,
    payload: payload,
  };
};
