import {
  FETCH_GALLERIES,
  FETCH_CATEGORIES,
  SET_LOADING_CATEGORIES,
  LOG_ERROR,
} from './gallery.types';
import { findIndex } from '../../utils/postFilters';

export const setLoadingCategories = () => ({
  type: SET_LOADING_CATEGORIES,
});

const loadFeaturedImages = async () => {
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/lk-cms/wp-json/wp/v2/categories?exclude=3,2,1`
  );
  const data = await res.json();

  // map a hero image to each array obj
  const newfilteredCategories = await Promise.all(
    data.map(async (element) => {
      const mediaQuery = `${process.env.REACT_APP_API_URL}/lk-cms/wp-json/wp/v2/media?categories[]=${element.id}&per_page=1`;
      const res2 = await fetch(mediaQuery);
      const data2 = await res2.json();

      // create a new hero property with src of image
      element.hero = data2[0].media_details.sizes.medium_large.source_url;
      return element;
    })
  );
  return newfilteredCategories;
};

const loadGallery = async (currentCategory) => {
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/lk-cms/wp-json/wp/v2/media?categories[]=${currentCategory.id}&per_page=100`
  );
  const data = await res.json();
  return data;
};

// gallery fetch for WallHeroImage
export const fetchGallery = (id) => async (dispatch, getState) => {
  const state = getState();
  if (!state.gallery.categories && !state.gallery.loadingCategories) {
    dispatch(setLoadingCategories());

    const galleryCategories = await loadFeaturedImages();

    dispatch({
      type: FETCH_CATEGORIES,
      payload: galleryCategories,
    });
  }

  if (id) {
    const state = getState();

    const { categories } = state.gallery;

    const indexOfId = findIndex(categories, id);

    const currentCategory = categories[indexOfId];

    if (!currentCategory.gallery) {
      const gallery = await loadGallery(currentCategory, indexOfId);
      gallery.id = indexOfId;

      dispatch({
        type: FETCH_GALLERIES,
        payload: gallery,
      });
    }
  }
};

export const logError = () => {};
