import { FETCH_POSTS, SET_LOADING, LOG_ERROR } from './post.types';

export const fetchPosts = () => async (dispatch, getState) => {
  if (!getState().posts.posts) {
    try {
      dispatch(setLoading());

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/lk-cms/wp-json/wp/v2/posts?_embed`
      );
      const data = await res.json();

      dispatch({
        type: FETCH_POSTS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: LOG_ERROR,
        payload: err,
      });
    }
  }
};

export const setLoading = () => {
  return {
    type: SET_LOADING,
  };
};

export const logError = () => {};
