import React from 'react';
import Spinner from '../spinner/Spinner.component';

const loadPageComponent = (PageComponent, url) => {
  class LoadedPageComponent extends React.Component {
    state = {
      page: {},
      isLoaded: false,
    };

    componentDidMount() {
      fetch(url)
        .then((response) => response.json())
        .then((pageData) =>
          this.setState({
            page: pageData,
            isLoaded: true,
          })
        )
        .catch((err) => console.log(err));
    }

    render() {
      const { isLoaded } = this.state;
      if (isLoaded) {
        return (
          <PageComponent
            page={this.state.page}
            isLoaded={this.state.isLoaded}
          />
        );
      }
      return <Spinner />;
    }
  }
  return LoadedPageComponent;
};

export default loadPageComponent;
