import React from 'react';
import loadPageComponent from './LoadPage.hoc';
import './About.styles.scss';

const About = (props) => {
  const { page } = props;
  return (
    <section className="about-page">
      <h1 className="about-page__header">{page.title.rendered}</h1>
      <picture className="about-page__hero-image">
        <source
          alt={page._embedded['wp:featuredmedia'][0].alt_text}
          srcSet={
            page._embedded['wp:featuredmedia'][0].media_details.sizes.medium
              .source_url
          }
          media="(max-width: 767px)"
        />
        <source
          alt={page._embedded['wp:featuredmedia'][0].alt_text}
          srcSet={
            page._embedded['wp:featuredmedia'][0].media_details.sizes.large
              .source_url
          }
          media="(max-width: 1023px)"
        />
        <img
          className="wall-hero__image-img"
          alt={page._embedded['wp:featuredmedia'][0].alt_text}
          src={
            page._embedded['wp:featuredmedia'][0].media_details.sizes.full
              .source_url
          }
        />
      </picture>
      <div
        className="about-page__intro"
        dangerouslySetInnerHTML={{ __html: page.content.rendered }}
      />
    </section>
  );
};

export default loadPageComponent(
  About,
  `${process.env.REACT_APP_API_URL}/lk-cms/wp-json/wp/v2/pages/6?_embed`
);
