import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import Header from './components/header/Header.component.jsx';
import Footer from './components/footer/Footer.component.jsx';
import Wall from './components/wall/Wall.component';
import ContactPage from './components/pages/Contact.component';
import AboutPage from './components/pages/About.component';
import BlogPost from './components/post/BlogPost.component';
import BlogPosts from './components/post/BlogPosts.component';
import KitchenPost from './components/post/KitchenPost.component';
import KitchenPosts from './components/post/KitchenPosts.component';
import GalleryCategories from './components/gallery/GalleryCategories.component';
import GalleryDisplay from './components/gallery/GalleryDisplay.component';
import Lightbox from './components/lightbox/Lightbox.component';

import store from './redux/store';

class App extends Component {
  render() {
    return (
      <>
        <Provider store={store}>
          <BrowserRouter>
            <Header />
            <main className="main">
              <Switch>
                <Route exact path="/" component={Wall} />
                <Route exact path="/about" component={AboutPage} />
                <Route exact path="/blog" component={BlogPosts} />
                <Route exact path="/blog/:id" component={BlogPost} />
                <Route exact path="/contact" component={ContactPage} />
                <Route exact path="/gallery" component={GalleryCategories} />
                <Route exact path="/gallery/:id" component={GalleryDisplay} />
                <Route exact path="/kitchen" component={KitchenPosts} />
                <Route exact path="/kitchen/:id" component={KitchenPost} />
              </Switch>
            </main>
            <Lightbox />
            <Footer />
          </BrowserRouter>
        </Provider>
      </>
    );
  }
}

export default App;
