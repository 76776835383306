import React from 'react';
import composedHOC from './LoadPosts.hoc';
import { filterByCategory } from '../../utils/postFilters';
import { Link } from 'react-router-dom';
import { BLOG_POSTS_CATEGORY } from './postCategory.types';
import './BlogPosts.styles.scss';

const BlogPosts = (props) => {
  const { post } = props;
  return (
    <section className="blog-posts">
      <h1 className="blog-posts__header">Leila Khan's Blog Posts</h1>
      {post.map((post) => (
        <article className="blog-posts__item" key={post.id}>
          <div className="blog-posts__item-inner-wrapper">
            <div className="blog-posts__item-copy">
              <Link
                className="blog-posts__item-header-link"
                to={`/blog/${post.id}`}
              >
                <h2 className="blog-posts__item-header">
                  {post.title.rendered}
                </h2>
                <p className="blog-posts__item-date">{post.formatted_date}</p>
              </Link>
              <div
                className="blog-posts__item-description"
                dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
              />
            </div>
            <Link to={`/blog/${post.id}`}>
              <picture className="blog-posts__item-hero-image">
                <source
                  alt={post._embedded['wp:featuredmedia'][0].alt_text}
                  srcSet={
                    post._embedded['wp:featuredmedia'][0].media_details.sizes
                      .medium.source_url
                  }
                  media="(max-width: 767px)"
                />
                <img
                  className="wall-hero__image-img"
                  alt={post._embedded['wp:featuredmedia'][0].alt_text}
                  src={
                    post._embedded['wp:featuredmedia'][0].media_details.sizes
                      .large.source_url
                  }
                />
              </picture>
            </Link>
          </div>
        </article>
      ))}
    </section>
  );
};

export default composedHOC(BlogPosts, filterByCategory, BLOG_POSTS_CATEGORY);
