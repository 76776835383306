import React, { Component } from 'react';
import { connect } from 'react-redux';
import './lightbox.styles.scss';
import {
  closeLightbox,
  nextImageChange,
  prevImageChange,
} from '../../redux/lightbox/lightbox.actions';

class Lightbox extends Component {
  closeLightboxClickHandler = () => {
    this.props.closeLightbox();
  };

  onPrevClickHandler = () => {
    this.props.prevImageChange();
  };

  onNextClickHandler = () => {
    this.props.nextImageChange();
  };

  render() {
    const {
      lightboxActive,
      activeGallery,
      currentImagePosition,
      arrowPrevDisabled,
      arrowNextDisabled,
    } = this.props.lightbox;

    if (lightboxActive) {
      return (
        <div className="lightbox-container active">
          <div
            className="innerWrapper"
            style={{
              backgroundImage: `url(${activeGallery[currentImagePosition].media_details.sizes.full.source_url})`,
            }}
          >
            <div className="controls">
              <button
                onClick={this.closeLightboxClickHandler}
                className="close"
              />
              <button
                onClick={this.onPrevClickHandler}
                className="prev"
                disabled={arrowPrevDisabled}
              />
              <button
                onClick={this.onNextClickHandler}
                className="next"
                disabled={arrowNextDisabled}
              />
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  lightbox: state.lightbox,
});

export default connect(mapStateToProps, {
  closeLightbox,
  nextImageChange,
  prevImageChange,
})(Lightbox);
