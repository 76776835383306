import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../spinner/Spinner.component';
import { fetchGallery } from '../../redux/gallery/gallery.actions';
import { setLightbox } from '../../redux/lightbox/lightbox.actions';
import { findIndex } from '../../utils/postFilters';
import './GalleryDisplay.styles.scss';

class GalleryDisplay extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchGallery(Number(id));
  }

  layoutRandomizer = (width, height) => {
    if (width > height) {
      return (
        'wall-gallery-display__image--landscape' +
        (Math.floor(Math.random() * 5) + 1)
      );
    }

    return (
      'wall-gallery-display__image--portrait' +
      (Math.floor(Math.random() * 5) + 1)
    );
  };

  onThumbnailClick = (gallery, id) => {
    this.props.setLightbox(gallery, id);
  };

  render() {
    const { categories } = this.props.gallery;
    const { id } = this.props.match.params;

    if (!categories) {
      return <Spinner />;
    }

    const indexOfId = findIndex(categories, id);

    if (categories[indexOfId].gallery) {
      return (
        <div className="gallery-display">
          <h2 className="gallery-display__header">
            {categories[indexOfId].name}
          </h2>
          {categories[indexOfId].gallery.map((image) => (
            <div
              className={
                'wall-gallery-display__image ' +
                this.layoutRandomizer(
                  image.media_details.sizes.large.width,
                  image.media_details.sizes.large.height
                )
              }
              key={image.id}
            >
              <picture>
                <source
                  alt={image.title.rendered}
                  srcSet={image.media_details.sizes.medium.source_url}
                  media="(max-width: 767px)"
                />
                <img
                  onClick={this.onThumbnailClick.bind(
                    this,
                    categories[indexOfId].gallery,
                    image.id
                  )}
                  alt={image.alt_text}
                  src={image.media_details.sizes.large.source_url}
                />
              </picture>
            </div>
          ))}
        </div>
      );
    }
    return <Spinner />;
  }
}

const mapStateToProps = (state) => ({
  gallery: state.gallery,
});

export default connect(mapStateToProps, {
  fetchGallery,
  setLightbox,
})(GalleryDisplay);
