import {
  FETCH_GALLERIES,
  SET_LOADING_CATEGORIES,
  LOG_ERROR,
  FETCH_CATEGORIES,
} from './gallery.types';

const initialState = {
  loadingCategories: false,
  error: null,
  categoriesLoaded: false,
  categories: null,
  latestCategory: null,
};

const findLatestGalleryId = (array) =>
  Math.max.apply(
    Math,
    array.map((o) => o.id)
  );

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_GALLERIES:
      const newState = { ...state };
      newState.categories[action.payload.id].gallery = action.payload;
      return newState;
    case FETCH_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        loadingCategories: false,
        categoriesLoaded: true,
        latestCategory: findLatestGalleryId(action.payload),
      };
    case SET_LOADING_CATEGORIES:
      return {
        ...state,
        loadingCategories: true,
      };
    case LOG_ERROR:
      // eslint-disable-next-line no-console
      console.error(action.payload);
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
