export const filterById = (posts, id) => {
  return posts.filter((post) => post.id === Number(id));
};

export const filterByLatest = (posts, category) => {
  const filteredPosts = posts.filter((post) => post.categories[0] === category);
  const latestPost = [...filteredPosts];
  latestPost.splice(1);
  return latestPost;
};

export const filterByCategory = (posts, category) => {
  return posts.filter((post) => post.categories[0] === category);
};

export const findIndex = (array, id) => {
  return array.findIndex((x) => x.id === Number(id));
};
