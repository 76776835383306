import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchPosts } from '../../redux/post/post.actions';
import Spinner from '../spinner/Spinner.component';
import { compose } from 'redux';

const loadPostsComponent = (PostComponent, filter, value) => {
  class LoadedPostsComponent extends Component {
    componentDidMount() {
      this.props.fetchPosts();
    }

    render() {
      const { posts } = this.props.posts;
      const { id } = this.props.match.params;

      if (!posts) {
        return <Spinner />;
      }

      return <PostComponent post={filter(posts, value ? value : id)} />;
    }
  }
  return LoadedPostsComponent;
};

const mapStateToProps = (state) => {
  return {
    posts: state.posts,
  };
};

const composedHOC = compose(
  connect(mapStateToProps, { fetchPosts }),
  loadPostsComponent
);

export default composedHOC;
