import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchGallery } from '../../redux/gallery/gallery.actions';
import Spinner from '../spinner/Spinner.component';
import { HERO_GALLERY_CATEGORY } from '../post/postCategory.types';
import './GalleryCategories.styles.scss';

class GalleryCategories extends Component {
  componentDidMount() {
    this.props.fetchGallery();
  }

  render() {
    const { categories } = this.props.gallery;

    if (!categories) {
      return <Spinner />;
    }

    const filteredCategories = categories.filter((category) => {
      return category.id !== HERO_GALLERY_CATEGORY;
    });

    return (
      <section className="gallery-categories">
        <h1 className="gallery-categories__header">
          Photo Galleries of Leila Khan
        </h1>
        {filteredCategories.map((category) => (
          <section className="gallery-categories__item" key={category.id}>
            <div className="gallery-categories__item-inner-wrapper">
              <div className="gallery-categories__copy">
                <Link
                  className="gallery-categories__item-header-link"
                  to={`/gallery/${category.id}`}
                >
                  <h2 className="gallery-categories__item-header">
                    {category.name}
                  </h2>
                </Link>
                <p className="gallery-categories__item-description">
                  {category.description}
                </p>
              </div>
              <Link
                className="gallery-categories__item-hero-link"
                to={`/gallery/${category.id}`}
              >
                <img
                  className="gallery-categories__item-hero-image"
                  alt={category.name}
                  src={category.hero}
                />
              </Link>
            </div>
          </section>
        ))}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  gallery: state.gallery,
});

export default connect(mapStateToProps, { fetchGallery })(GalleryCategories);
