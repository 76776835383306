import React from 'react';
import composedHOC from './LoadPosts.hoc';
import { filterById } from '../../utils/postFilters';
import { Link } from 'react-router-dom';
import './KitchenPost.styles.scss';

const KitchenPost = (props) => {
  const { post } = props;
  return (
    <section className="kitchen-post">
      <div className="kitchen-post__inner-wrapper">
        <div className="kitchen-post__col1">
          <div className="kitchen-post__copy">
            <h1 className="kitchen-post__header">{post[0].title.rendered}</h1>
            <div
              className="kitchen-post__wp-content"
              dangerouslySetInnerHTML={{ __html: post[0].content.rendered }}
            />
            <p>
              <Link className="btn-std btn-std--group" to={'/kitchen'}>
                Back
              </Link>
              <Link className="btn-std" to={'/kitchen'}>
                Print
              </Link>
            </p>
          </div>
        </div>
        <div className="kitchen-post__col2">
          <div className="kitchen-post__hero">
            <picture>
              <source
                alt={post[0].title.rendered}
                srcSet={
                  post[0]._embedded['wp:featuredmedia'][0].media_details.sizes
                    .medium.source_url
                }
                media="(max-width: 767px)"
              />
              <img
                className="kitchen-post__hero-image"
                alt={post[0]._embedded['wp:featuredmedia'][0].alt_text}
                src={
                  post[0]._embedded['wp:featuredmedia'][0].media_details.sizes
                    .large.source_url
                }
              />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
};

export default composedHOC(KitchenPost, filterById, null);
