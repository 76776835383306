import React from 'react';
import WallHeroImage from './WallHeroImage.component';
import WallHeroQuote from './WallHeroQuote.component';
import './WallHero.styles.scss';

export default function WallHero() {
  return (
    <div className="wall-hero">
      <WallHeroImage id={6} />
      <WallHeroQuote />
    </div>
  );
}
