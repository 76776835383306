import React from 'react';
import loadPageComponent from './LoadPage.hoc';
import './Contact.styles.scss';

const Contact = (props) => {
  const { page } = props;
  return (
    <section className="contact-page">
      <h1 className="contact-page__header">{page.title.rendered}</h1>
      <div
        className="contact-page__contacts"
        dangerouslySetInnerHTML={{ __html: page.content.rendered }}
      />
    </section>
  );
};

export default loadPageComponent(
  Contact,
  `${process.env.REACT_APP_API_URL}/lk-cms/wp-json/wp/v2/pages/8`
);
