import React from 'react';
import './scroll-icon.styles.scss';

function ScrollIcon(props) {
  return (
    <div className="scrollicon__wrapper">
      <svg
        className="scrollicon__svg"
        xmlns="http://www.w3.org/2000/svg"
        width="78.841"
        height="43.841"
        preserveAspectRatio="none"
      >
        <g
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="6"
        >
          <path d="M4.242 4.242l35.355 35.355M74.597 4.242L39.242 39.597" />
        </g>
      </svg>
    </div>
  );
}

export default ScrollIcon;
