import {
  SET_LIGHTBOX,
  CLOSE_LIGHTBOX,
  NEXT_IMAGE_CHANGE,
  PREV_IMAGE_CHANGE,
} from './lightbox.types';

const initialState = {
  lightboxActive: false,
  currentImagePosition: 0,
  activeGallery: null,
  arrowPrevDisabled: true,
  arrowNextDisabled: true,
};

const checkPrevArrowState = (index) => {
  return index === 0 ? true : false;
};

const checkNextArrowState = (gallery, index) => {
  return gallery.length - 1 === index ? true : false;
};

const setPrevImage = (currentIndex) => {
  return currentIndex > 0 ? currentIndex - 1 : currentIndex;
};

const setNextImage = (currentIndex, gallery) => {
  return currentIndex < gallery.length - 1 ? currentIndex + 1 : currentIndex;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LIGHTBOX:
      return {
        ...state,
        lightboxActive: true,
        activeGallery: action.payload.gallery,
        currentImagePosition: action.payload.indexOfId,
        arrowPrevDisabled: checkPrevArrowState(action.payload.indexOfId),
        arrowNextDisabled: checkNextArrowState(
          action.payload.gallery,
          action.payload.indexOfId
        ),
      };
    case CLOSE_LIGHTBOX:
      return {
        ...state,
        activeGallery: null,
        lightboxActive: false,
      };
    case PREV_IMAGE_CHANGE:
      return {
        ...state,
        currentImagePosition: setPrevImage(state.currentImagePosition),
        arrowPrevDisabled: checkPrevArrowState(state.currentImagePosition - 1),
        arrowNextDisabled: checkNextArrowState(
          state.activeGallery,
          state.currentImagePosition - 1
        ),
      };
    case NEXT_IMAGE_CHANGE:
      return {
        ...state,
        currentImagePosition: setNextImage(
          state.currentImagePosition,
          state.activeGallery
        ),
        arrowPrevDisabled: checkPrevArrowState(),
        arrowNextDisabled: checkNextArrowState(
          state.activeGallery,
          state.currentImagePosition + 1
        ),
      };
    default:
      return state;
  }
}
